@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&family=Lato:wght@400;700&family=Montserrat:wght@400;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  font-family: "Lato";
}

body {
  min-height: 100vh;
  // background-color: #fafdfd !important;
  background: linear-gradient(114.88deg, #F3F9F9 0%, #F9FCFC 85.59%);

  // background-color: #fafcfc !important;
}